import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import { clearAuthInfo } from "../reducers/authInfoSlice";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authInfo = useSelector((state: any) => state.authInfo);
  const accessToken = authInfo?.data?.access_token;

  const [isValidToken, setIsValidToken] = useState(true);

  const validateToken = async () => {
    try {
      if (!accessToken) {
        console.error("No access token found");
        setIsValidToken(false);
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_SYSTEM_DOMAIN}/auth-portal/fleet-auth-service/valid/token?accessToken=${accessToken}`
      );

      if (response.data.active) {
        setIsValidToken(true);
      } else {
        setIsValidToken(false);
        dispatch(clearAuthInfo());
      }
    } catch (error) {
      console.error("Token validation failed. Error:", error);
      setIsValidToken(false);
      dispatch(clearAuthInfo());
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      validateToken();
    }, 120000);
    return () => clearInterval(interval);
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (!isValidToken) {
      navigate("/login", { replace: true });
    }
  }, [isValidToken]);
  return authInfo?.data === null ? (
    <Navigate to="/" replace state={{ from: location }} />
  ) : isValidToken ? (
    <Outlet />
  ) : null;
};

export default ProtectedRoute;


