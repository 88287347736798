import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Forgotpassword from "../pages/forgotpassword";
import Loginpage from "../pages/login";
import Account from "../pages/myaccount";
import Resetpassword from "../pages/resetpassword";
import ProtectedRoute from "./ProtectedRoute";
import Logout from "../pages/logout";
import FleetAnalytics from "../pages/fleetanalytics";

const router = createBrowserRouter([
  {
    children: [
      {
        path: "/",
        element: <Navigate to="/login" replace />,
      },
      {
        path: "/login",
        element: <Loginpage />,
      },
      {
        path: "/forgotpassword",
        element: <Forgotpassword />,
      },
      {
        path: "/resetpassword",
        element: <Resetpassword />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
    ],
  },
  {
    element: <ProtectedRoute />,  
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/account",
        element: <Account />,
      },
      {
        path: "/fleet-analytics",
        element: <FleetAnalytics />,
      },
    ],
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}

